
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// 設定要導入的data格式;
import {
  NewClientData,
  NewMaterialsData,
  NewQuantityData,
  NewWarehousingData,
  NewProcessData,
  NewInspectionData,
  NewPurchaseOrder,
} from "@/core/data/products";

export default defineComponent({
  name: "overview",
  components: {},

  setup() {
    // 導入資料
    const Client = ref<NewClientData>({
      ID: "",
      Name: "",
      Number: "",
      OrderDate: "",
      Quantity: "",
      Sales: "",
    });
    const Material = ref<NewMaterialsData>({
      Name: "",
      Number: "",
      Attributes: "",
      Material: "",
      Unit: "",
    });
    const Quantity = ref<NewQuantityData>({
      Project: "",
      Specification: "",
      Methods: "",
      Proportion: "",
      Characteristic: "",
    });
    const Warehousing = ref<NewWarehousingData>({
      Number: "",
      Name: "",
      PurchaseDate: "",
      Quantity: "",
      Mark: "",
    });
    const Process = ref<NewProcessData>({
      ID: "",
      Responsibility: "",
      Number: "",
      ShipDate: "",
    });
    const Inspection = ref<NewInspectionData>({
      Person: "",
      MeasuringNumber: "",
      MeasuringDate: "",
      MeasuringMethod: "",
      MeasuringQuantity: "",
      MeasuringItem: "",
      MeasuringAtion: "",
      QualifiedNumber: "",
      Determination: "",
    });
    const PurchaseOrder = ref<NewPurchaseOrder>({
      ID: "",
      Name: "",
      InspectionDate: "",
      Allow: "",
      Reject: "",
      Determination: "",
    });
    const PurchaseOrderList = ref<Array<NewPurchaseOrder>>([
      {
        ID: "",
        Name: "",
        InspectionDate: "",
        Allow: "",
        Reject: "",
        Determination: "",
      },
    ]);

    onMounted(() => {
      setCurrentPageBreadcrumbs("排程資料輸入2", ["產線管理自動排程資訊"]);
    });
    return {
      Client,
      Material,
      Quantity,
      Warehousing,
      Process,
      Inspection,
      PurchaseOrder,
      PurchaseOrderList,
    };
  },
});
